
    import _Handlebars from 'handlebars/runtime';
    var Handlebars = _Handlebars.default;
    import "i18n!registration.saml_dialog";
import "@canvas/handlebars-helpers/index.coffee";

    var template = Handlebars.template, templates = Handlebars.templates = Handlebars.templates || {};
    var name = '/srv/canvas/current/ui/shared/signup-dialog/jst/samlDialog';
    templates[name] = template(function (Handlebars,depth0,helpers,partials,data) {
  this.compilerInfo = [4,'>= 1.0.0'];
helpers = this.merge(helpers, Handlebars.helpers); data = data || {};
  var buffer = "", stack1, helper, options, functionType="function", escapeExpression=this.escapeExpression, helperMissing=helpers.helperMissing, self=this;

function program1(depth0,data) {
  
  var buffer = "", stack1, helper;
  buffer += "\n            <div class=\"control-group\">\n                <div class=\"controls\">\n                    <label class=\"checkbox\">\n                        <input type=\"checkbox\" name=\"user[terms_of_use]\" value=\"1\">\n                        ";
  if (helper = helpers.terms_html) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.terms_html); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n                    </label>\n                </div>\n            </div>\n        ";
  return buffer;
  }

function program3(depth0,data) {
  
  
  return "\n        <div class=\"control-group\">\n            <div class=\"controls\">\n            <div class=\"g-recaptcha\"></div>\n            </div>\n        </div>\n        ";
  }

  buffer += "<div class=\"registration-dialog\">\n    <form class=\"form-horizontal bootstrap-form\" method=\"post\" action=\"";
  if (helper = helpers.path) { stack1 = helper.call(depth0, {hash:{},data:data}); }
  else { helper = (depth0 && depth0.path); stack1 = typeof helper === functionType ? helper.call(depth0, {hash:{},data:data}) : helper; }
  buffer += escapeExpression(stack1)
    + "\">\n        <div class=\"control-group\">\n            <label class=\"control-label\" for=\"parent_name\">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_inferred_key': (true)
  },data:data},helper ? helper.call(depth0, "your_name_7665e1d8", "Your Name", options) : helperMissing.call(depth0, "t", "your_name_7665e1d8", "Your Name", options)))
    + "</label>\n            <div class=\"controls\">\n                <input type=\"text\" id=\"parent_name\" name=\"user[name]\">\n            </div>\n        </div>\n        <div class=\"control-group\">\n            <label class=\"control-label\" for=\"parent_email\">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_inferred_key': (true)
  },data:data},helper ? helper.call(depth0, "your_email_b5364bee", "Your Email", options) : helperMissing.call(depth0, "t", "your_email_b5364bee", "Your Email", options)))
    + "</label>\n            <div class=\"controls\">\n                <input type=\"text\" id=\"parent_email\" name=\"pseudonym[unique_id]\"><div id=\"parent_email_error\" class='error_message'></div>\n            </div>\n        </div>\n        <div class=\"control-group\">\n            <label class=\"control-label\" for=\"parent_child_username\">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_inferred_key': (true)
  },data:data},helper ? helper.call(depth0, "child_s_username_dde3aa9", "Child's Username", options) : helperMissing.call(depth0, "t", "child_s_username_dde3aa9", "Child's Username", options)))
    + "</label>\n            <div class=\"controls\">\n                <input type=\"text\" id=\"parent_child_username\" name=\"observee[unique_id]\"><div id=\"parent_child_username_error\" class='error_message'></div>\n            </div>\n        </div>\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.terms_required), {hash:{},inverse:self.noop,fn:self.program(1, program1, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        ";
  stack1 = helpers['if'].call(depth0, (depth0 && depth0.recaptcha), {hash:{},inverse:self.noop,fn:self.program(3, program3, data),data:data});
  if(stack1 || stack1 === 0) { buffer += stack1; }
  buffer += "\n        <div class=\"control-group\">\n        <p>"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_inferred_key': (true)
  },data:data},helper ? helper.call(depth0, "you_will_be_redirected_to_a_login_page_where_you_w_fc8df402", "You will be redirected to a Login page where you will need to log in with your child's credentials.", options) : helperMissing.call(depth0, "t", "you_will_be_redirected_to_a_login_page_where_you_w_fc8df402", "You will be redirected to a Login page where you will need to log in with your child's credentials.", options)))
    + "</p>\n        </div>\n        <div class=\"control-group\">\n            <div class=\"controls\">\n                <input type=\"hidden\" name=\"user[initial_enrollment_type]\" value=\"observer\">\n                <button class=\"btn btn-primary\" type=\"submit\">"
    + escapeExpression((helper = helpers.t || (depth0 && depth0.t),options={hash:{
    'i18n_inferred_key': (true)
  },data:data},helper ? helper.call(depth0, "start_participating_f0c75256", "Start Participating", options) : helperMissing.call(depth0, "t", "start_participating_f0c75256", "Start Participating", options)))
    + "</button>\n            </div>\n        </div>\n    </form>\n</div>";
  return buffer;
  });
    ;
    ;
    export default templates[name];
  